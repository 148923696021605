import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createTip, deleteTip, getAllTips, updateTip
} from "../../services/user.service";
import { displayMessage } from "./../common/common";


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function validateField(val) {
  return val != "";
}

const Tips = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [backDropOpen, setBackDropOpen] = useState(true);
  const columns =[
    { title: "Tip ID", field: "id", editable: "never" },
    {
      title: "Tip Header",
      field: "header",

      validate: (rowData) => {
        if (validateField(rowData.header)) {
        }

        return validateField(rowData.header) && rowData.header
          ? ""
          : "Tip Header is required";
      },
    },
    {
      title: "Tip Detail",
      field: "details",

      validate: (rowData) => {
        if (validateField(rowData.details)) {
        }

        return validateField(rowData.details) && rowData.details
          ? ""
          : "Tip Detail is required";
      },
    },

  ];

  useEffect(() => {
    getAllTips().then((res) => {
      setData(res.data.userTips);
      setBackDropOpen(false);
    });
  }, []);

  return (
    <div className="margindiv usersTable">
      <MaterialTable
        localization={{
          body: {
            deleteTooltip: "Deactivate",
          },
        }}

        options={{
          exportButton: true,
          exportAllData: true,
        }}
        icons={tableIcons}
        title="Tooltips"
        columns={columns}
        data={data}
        editable={{
          onRowAdd: (newData) => {
            return new Promise((resolve, reject) => {
              if (
                newData.header != "" &&
                newData.details != ""
              ) {
                createTip(
                  newData.header,
                  newData.details,
                  "USER"
                ).then((res) => {

                  newData["id"] = res.data.tip.id;
                  //   newData["uuid"] = res.data.user.uuid;
                  //   newData["isVerified"] = 0;
                  //   newData["typeID"] = 2;
                  setData([...data, newData]);

                  resolve();
                  dispatch(displayMessage("success", "Tooltip added successfully"));

                });
              } else {
                reject();
              }
            });
          },
          onRowUpdate: (newData, oldData) => {
            return new Promise((resolve, reject) => {

              if (
                newData.header != "" &&
                newData.details != ""
              ) {

                updateTip(
                  oldData.id,
                  newData.header,
                  newData.details,
                  "USER"
                ).then((res) => {
                  const dataUpdate = [...data];
                  const index = oldData.tableData.id;

                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);

                  resolve();
                  dispatch(displayMessage("success", "Tooltip updated successfully"));

                });
              } else {
                reject();
              }
            });
          },
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              deleteTip(oldData.id).then((res) => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                resolve();
                dispatch(displayMessage("success", "Tooltip deleted successfully"));

              });
            }),
        }}
      />
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default Tips;
