import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SunEditor, { buttonList } from "suneditor-react";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import { Input } from "@material-ui/core";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPlans, updatePlan } from "../../services/user.service";
import { displayMessage } from "./../common/common";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function validateField(val) {
  return val != "";
}

const Subscriptions = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [backDropOpen, setBackDropOpen] = useState(true);
  const columns = [
    {
      title: "Name",
      field: "name",

      validate: (rowData) => {
        if (validateField(rowData.name)) {
        }

        return validateField(rowData.name) && rowData.name
          ? ""
          : "Name is required";
      },
    },
    {
      title: "Storage",
      field: "metadata.storage",
      editComponent: (props) => (
        <Input
          type="number"
          // disabled={
          //   props.rowData.organizationID &&
          //   props.rowData.organizationID !== "null" &&
          //   !props.rowData.isOrganizationAdmin
          // }
          inputProps={{ min: 0 }}
          defaultValue={
            parseInt(props.value.toString().replace(",", "")) / 1024
          }
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      type: "numeric",
      render: (col) => {
        return (
          (
            parseInt(col.metadata.storage.toString().replace(",", "")) / 1024
          ).toFixed(2) + " GB"
        );
      },
      editable: "onUpdate",
    },
    // {
    //   title: "Name",
    //   field: "name",

    //   validate: (rowData) => {
    //     if (validateField(rowData.header)) {
    //     }

    //     return validateField(rowData.header) && rowData.header
    //       ? ""
    //       : "Tip Header is required";
    //   },
    // },
    {
      title: "Description",
      field: "description",

      validate: (rowData) => {
        if (validateField(rowData.description)) {
        }

        return validateField(rowData.description) && rowData.description
          ? ""
          : "Description is required";
      },
    },
    {
      title: "Product Info",
      field: "product_info",
      cellStyle: {
        width: "100%",
      },
      editComponent: (props) => (
        <SunEditor
          onChange={(e) => props.onChange(e)}
          setOptions={{
            height: 200,
            buttonList: buttonList.complex, // Or Array of button list, eg. [['font', 'align'], ['image']]
            // plugins: [font] set plugins, all plugins are set by default
            // Other option
          }}
          defaultValue={props.rowData.product_info}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
            }
          }}
        />
      ),
      //   validate: (rowData) => {
      //     if (validateField(rowData.product_info)) {
      //     }

      //     return validateField(rowData.product_info) && rowData.product_info
      //       ? ""
      //       : "Tip Detail is required";
      //   },
    },
    { title: "ID", field: "id", editable: "never" },
  ];

  useEffect(() => {
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    link.href =
      "https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css";
    getPlans().then((res) => {
      setData(res?.data?.data);
      setBackDropOpen(false);
    });
  }, []);

  return (
    <div className="margindiv usersTable">
      <MaterialTable
        localization={{
          body: {
            deleteTooltip: "Deactivate",
          },
        }}
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        icons={tableIcons}
        title="Subscriptions"
        columns={columns}
        data={data}
        editable={{
          //   onRowAdd: (newData) => {
          //     return new Promise((resolve, reject) => {
          //       if (newData.header != "" && newData.details != "") {
          //         createTip(newData.header, newData.details, "USER").then(
          //           (res) => {
          //             newData["id"] = res.data.tip.id;
          //             //   newData["uuid"] = res.data.user.uuid;
          //             //   newData["isVerified"] = 0;
          //             //   newData["typeID"] = 2;
          //             setData([...data, newData]);

          //             resolve();
          //             dispatch(
          //               displayMessage("success", "Tooltip added successfully")
          //             );
          //           }
          //         );
          //       } else {
          //         reject();
          //       }
          //     });
          //   },
          onRowUpdate: (newData, oldData) => {
            return new Promise((resolve, reject) => {
              if (
                newData.name != "" &&
                newData.description != "" &&
                newData.metadata.storage != ""
              ) {
                try {
                  updatePlan(newData.id, {
                    name: newData.name,
                    storage:
                      oldData.metadata.storage == newData.metadata.storage
                        ? oldData.metadata.storage
                        : newData.metadata.storage * 1024,
                    description: newData.description,
                    product_info: newData.product_info,
                  })
                    .then((res) => {
                      const dataUpdate = [...data];
                      const index = oldData.tableData.id;
                      newData.metadata.storage =
                        oldData.metadata.storage == newData.metadata.storage
                          ? oldData.metadata.storage
                          : newData.metadata.storage * 1024;

                      dataUpdate[index] = newData;
                      setData([...dataUpdate]);

                      resolve();
                      dispatch(
                        displayMessage("success", "Plan updated successfully")
                      );
                    })
                    .catch((error) => {
                      const message =
                        error?.response?.data?.message ||
                        error?.message ||
                        "User not created";
                      dispatch(displayMessage("error", message));
                      reject();
                    });
                } catch (error) {
                  dispatch(displayMessage("error", "Error in updating Plan"));
                }
              } else {
                reject();
              }
            });
          },
          //   onRowDelete: (oldData) =>
          //     new Promise((resolve, reject) => {
          //       deleteTip(oldData.id).then((res) => {
          //         const dataDelete = [...data];
          //         const index = oldData.tableData.id;
          //         dataDelete.splice(index, 1);
          //         setData([...dataDelete]);
          //         resolve();
          //         dispatch(
          //           displayMessage("success", "Tooltip deleted successfully")
          //         );
          //       });
          //     }),
        }}
      />
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default Subscriptions;
